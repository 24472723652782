import React from 'react'

const WebDevelopment = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Web Development</h3>
        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">HTML & CSS</h3>
                    </div>
                </div>
{/* HTML */}

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">JavaScript</h3>
                    </div>
                </div>
{/* JS */}
                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">Bootstrap</h3>
                    </div>
                </div>
{/* Bootstrap */}

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">React</h3>
                    </div>
                </div>
{/* React */}

            </div>

            <div className="skills__group">

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">Flask</h3>
                    </div>
                </div>
{/* Flask */}

                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">MongoDB</h3>
                    </div>
                </div>
{/* MongoDB */}
                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">FastAPI</h3>
                    </div>
                </div>
{/* FastAPI */}
                <div className="skills__data">
                    <i className="bx bx-badge-check"></i>
                    <div>
                        <h3 className="skills__name">Node.js</h3>
                    </div>
                </div>
{/* Node.js */}
            </div>

        </div>
    </div>
  )
}

export default WebDevelopment